<template>
  <div class="row postcode-autocomplete">
    <div class="col-12 col-sm-7 postcode-autocomplete__input-col">
      <div class="postcode-autocomplete__input">
        <InputText
          v-model="postcode"
          :name="postcodeValidationName"
          :rules="rules"
          type="text"
          placeholder="Postcode"
          :pattern="'[a-zA-Z0-9\ ]+'"
          validate
          :show-errors="false"
        />
        <button
          class="postcode-autocomplete__geolocation"
          @click="getGeolocation()"
        >
          <OsIcon
            name="Location"
            class="text-blue-600"
            :class="{ 'animation--pulse-fade': loadingLocation }"
          />
        </button>
      </div>
    </div>
    <div class="col-12 col-sm-5">
      <InputDistance v-model="distance" :expand-upwards="expandUpwards" />
    </div>

    <div v-if="postCodeErrors?.length" class="col-12">
      <OsError>
        {{ postCodeErrors }}
      </OsError>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ICoords } from '~/types/postcode'
import { IFiltersRadius } from '~/types/filters'

defineProps({
  rules: {
    type: String,
    required: true
  },
  expandUpwards: {
    type: Boolean,
    default: false
  }
})

const radius = defineModel({
  type: Object as PropType<IFiltersRadius>,
  required: true
})

const emit = defineEmits(['errors'])

const loadingLocation = ref(false)

// validation
const postcodeValidationName = 'input-postcode'
const { setErrors, errors } = useForm()

const postCodeErrors = computed(() => errors.value?.[postcodeValidationName])

watch(postCodeErrors, (val) => {
  emit('errors', val)
})

const postcode = computed<string>({
  get() {
    return !!radius.value.Lat && !!radius.value.Lon
      ? 'Current location'
      : radius.value.Postcode || ''
  },
  set(value) {
    if (value !== 'Current location') {
      radius.value = filterUtils.updateRadius(radius.value, { Postcode: value })
    }
  }
})

const distance = computed({
  get() {
    return radius.value.Distance || 25
  },
  set(value) {
    radius.value = filterUtils.updateRadius(radius.value, { Distance: value })
  }
})

const getGeolocation = () => {
  loadingLocation.value = true

  const geoSuccess = (position: { coords: ICoords }) => {
    loadingLocation.value = false

    radius.value = filterUtils.updateRadius(radius.value, {
      Lat: position.coords.latitude,
      Lon: position.coords.longitude
    })
  }

  const geoError = (error: GeolocationPositionError) => {
    loadingLocation.value = false

    setErrors({
      [postcodeValidationName]: [error.message]
    })
  }

  navigator.geolocation.getCurrentPosition(geoSuccess, geoError)
}
</script>

<style lang="scss">
.postcode-autocomplete__input {
  position: relative;
  width: 100%;

  .postcode-autocomplete--full & {
    max-width: none;
  }

  input {
    text-transform: uppercase;

    ~ svg {
      right: 42px;
    }
  }

  .textInput__input--isValid {
    &:not(.textInput__input--hasValue) {
      border-color: $grey--light;
    }
  }
}

.postcode-autocomplete__geolocation {
  position: absolute;
  top: rem(18);
  right: rem(8);
  border: 0;
  background: none;
  cursor: pointer;
}
</style>
